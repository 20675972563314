import './App.css';
import KanbanBoard from './components';
// import KanbanBoard from './components/KanbanBoard';
const title: string = "Kanban Board";

function App() {
  return (
    <div className="App">
      <div className='hydrated'><nav className="app-header app-title">{title}</nav></div>
      <KanbanBoard />
    </div>
  );
}

export default App;
