import React, {ChangeEvent, useState} from "react";
import "./index.css";
import {Task} from "../interfaces/Task";



const KanbanBoard: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([
    {name: '1', stage: 0},
    {name: '2', stage: 0},
  ]);

  // If we want to create more stagesNames  -->  This stagesNames not need setState but if we want more dinamic 
  // We can use only --> const stagesNames = ['Backlog', 'To Do', 'Ongoing', 'Done']
  const [stagesNames, setStagesNames] = useState<string[]>(['Backlog', 'To Do', 'Ongoing', 'Done']);
  const [isEditIndexStages, setIsEditIndexStages] = useState<number | null>(null);
  const [taskName, setTaskName] = useState<string>('');
  const [editingStageName, setEditingStageName] = useState<string>('');
  // This for loop, we can code in one line as below
  // const stagesTasks: Task[][] = Array.from({length: stagesNames.length}, () => []);
  const stagesTasks: Task[][] = [];
  for (let i = 0; i < stagesNames.length; ++i) {
    stagesTasks.push([]);
  }

  //  in a real application ,this would be an api call and we would apply stagesTasks[stageId] then
  for (const task of tasks) {
    const stageId = task.stage;
    stagesTasks[stageId].push(task);
  }

  /*
    tasks.forEach(task => {
      const stageId = task.stage;
      stagesTasks[stageId].push(task);
    });
  */


  // Add Task
  const addTask = () => {
    if (taskName.trim() !== '') { // Imporve by validate the --> Input from user we should do validate before submit
      // <script></script>
      // &lt;script&gt;&lt;/script&gt;
      const sanitizedTaskName = decodeURIComponent(taskName);
      setTasks([...tasks, {name: sanitizedTaskName, stage: 0}]);
      setTaskName('');
    }
  };

  // Move Task
  const moveTask = (taskName: string, direction: number) => {
    setTasks(tasks.map(task =>
      task.name === taskName ? {...task, stage: Math.max(0, Math.min(task.stage + direction, stagesNames.length - 1))} : task));
  };

  // Delete Task
  const deleteTask = (taskName: string) => {
    setTasks(tasks.filter(task => task.name !== taskName));
  };

  // handleTaskName
  const handleTaskName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskName(e.target.value);
  };

  // Improve ==> Add handle KeyDown 
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addTask();
    }
  };

  // Handle Stage Name Change
  const handleStageNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditingStageName(e.target.value);
  };

  // Save Stage Name
  const handleSaveStateName = (index: number) => {
    const updatedStagesNames = [...stagesNames];
    updatedStagesNames[index] = editingStageName;
    setStagesNames(updatedStagesNames);
    setIsEditIndexStages(null);
  };

  // Handle Edit Stages
  const handleEditStages = (index: number) => {
    setEditingStageName(stagesNames[index]);
    setIsEditIndexStages(isEditIndexStages === index ? null : index);
  };



  return (
    <div className="mt-20 layout-column justify-content-center align-items-center">
      <section className="mt-50 layout-row align-items-center justify-content-center">
        <input id="create-task-input" type="text" className="large" placeholder="New task name" data-test-id="create-task-input" value={taskName}
          onChange={handleTaskName} onKeyDown={handleKeyDown} />
        <button type="submit" className="ml-30" data-test-id="create-task-button" onClick={addTask}>Create task</button>
      </section>
      <div className="mt-50 layout-row">
        {stagesTasks.map((tasks, i) => {
          return (
            <div className="card outlined ml-20 mt-0" key={`${i}`}>
              <div className="card-text">
                {isEditIndexStages === i ? (
                  <>
                    <input
                      type="text"
                      value={editingStageName}
                      onChange={handleStageNameChange}
                      onBlur={() => handleSaveStateName(i)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSaveStateName(i);
                        }
                      }}
                    />
                    <button onClick={() => handleSaveStateName(i)}>Save</button>
                  </>
                ) : (
                  <span>
                    <h4>{stagesNames[i]}</h4>
                    <button onClick={() => handleEditStages(i)}>Edit</button>
                  </span>
                )}
                <ul className="styled mt-50" data-test-id={`stage-${i}`}>
                  {tasks.map((task, index) => {
                    return <li className="slide-up-fade-in" key={`${i}${index}`}>
                      <div className="li-content layout-row justify-content-between align-items-center">
                        <span data-test-id={`${task.name.replaceAll(' ', '-')}-name`}>{task.name}</span>
                        <div className="icons">
                          <button className="icon-only x-small mx-2"
                            data-test-id={`${task.name.split(' ').join('-')}-back`}
                            disabled={task.stage === 0}
                            onClick={() => moveTask(task.name, -1)}>
                            <i className="material-icons">arrow_back</i>
                          </button>
                          <button className="icon-only x-small mx-2" data-test-id={`${task.name.split(' ').join('-')}-forward`}
                            disabled={task.stage === stagesNames.length - 1}
                            onClick={() => moveTask(task.name, 1)}>
                            <i className="material-icons">arrow_forward</i>
                          </button>
                          <button className="icon-only danger x-small mx-2" data-test-id={`${task.name.split(' ').join('-')}-delete`}
                            onClick={() => deleteTask(task.name)}
                          >
                            <i className="material-icons">delete</i>
                          </button>
                        </div>
                      </div>
                    </li>;
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div >
  );
};

export default KanbanBoard;
